<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Cashback Program</h1>
    </div>

    <div>
      <b-row>
        <b-col md="3">
          <div class="card sideCard container">
            <ul>
              <li
              v-if="validTab.includes('Cashback Brand')"
                :class="activeComponent == 'Brands' ? 'active' : ''"
                @click="handleActiveComponent('Brands')"
              >
                <img
                  v-if="activeComponent == 'Brands'"
                  src="../../../assets/images/svg/banner-active.svg"
                />
                <img v-else src="../../../assets/images/svg/banner.svg" />
                <span class="ml-1">Brands</span>
              </li>
              <li
              v-if="validTab.includes('Cashback Data')"
                :class="activeComponent == 'Data' ? 'active' : ''"
                @click="handleActiveComponent('Data')"
              >
                <img
                  v-if="activeComponent == 'Data'"
                  src="../../../assets/images/svg/activity-active.svg"
                />
                <img v-else src="../../../assets/images/svg/activity.svg" />
                <span class="ml-1"> Data </span>
              </li>
              <li
              v-if="validTab.includes('Cashback Users')"
                :class="activeComponent == 'Users' ? 'active' : ''"
                @click="handleActiveComponent('Users')"
              >
                <img
                  v-if="activeComponent == 'Users'"
                  src="../../../assets/images/svg/priority-active.svg"
                />
                <img v-else src="../../../assets/images/svg/priority.svg" />
                <span class="ml-1">
                  Users
                </span>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="9">
          <div class="container-fluid">
            <div v-if="activeComponent == 'Brands' && validTab.includes('Cashback Brand')">
              <div v-if="subModule == 'UpdateLatestPriority'">
                <UpdateLatestPriority @handleClickBack="handleClickBack"/>
              </div>
              <div v-else-if="subModule == 'UpdatePopularPriority'">
                <UpdatePopularPriority  @handleClickBack="handleClickBack"/>
              </div>
              <cashback-brand v-else @clickButton="clickButton"/>
            </div>
            <div v-if="activeComponent == 'Data' && validTab.includes('Cashback Data')">
              <cashback-data />
            </div>
            <div v-if="activeComponent == 'Users' && validTab.includes('Cashback Users')">
              <cashback-users/>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import CashbackBrand from "./Cashback-Brand.vue";
import CashbackData from "./Cashback-Data.vue";
import CashbackUsers from "./Cashback-Users.vue";
import UpdateLatestPriority from "./UpdateLatestPriority.vue";
import UpdatePopularPriority from "./UpdatePopularPriority.vue";
import { mapState } from 'vuex';
export default {
  components: {
    CashbackBrand,
    CashbackData,
    CashbackUsers,
    UpdateLatestPriority,
    UpdatePopularPriority
  },
  data() {
    return {
      data: {},
      clientStoreData: [
        { name: "Noon" },
        { name: "Sephora" },
        { name: "American Eagle" },
        { name: "Amazon" },
        { name: "Namshi" },
        { name: "H&M" },
      ],
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      activeComponent: "Brands",
      validTab:[],
      subModule:''
    };
  },
   watch: {
    'sidebar': {
      handler: 'checkIsValid',
      immediate: true, 
    },
  '$store.state.application': {
    handler: 'checkIsValid',
    immediate: true,
  },
  },
  computed:{
      ...mapState(['sidebar']),
  },
  methods: {
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.filter(data =>data.name == 'Cashback Program');
        console.log(is_valid)
        if(is_valid.length == 0){
          this.$router.push('/')
        }else{
        is_valid[0].rolePermission.map(item => {
          this.validTab.push(item.permissionlist_id.name)
        })
      console.log(this.validTab.includes('Cashback Brand'))
      this.$forceUpdate()
        }
      }
    },
    clickButton(value){
      this.subModule = value
    },
    handleClickBack(){
      this.subModule = ''
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleActiveComponent(item) {
        this.subModule = ''
      this.activeComponent = item;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.container {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
}
.sideCard ul {
  list-style-type: none;
  margin-top: 2rem;
}
.sideCard ul li {
  padding: 1rem 1rem;
  cursor: pointer;
}
html {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.sideCard li.active {
  background: #f4f9fd;
  border-radius: 14px;
  color: #0a1629;
}
.sideCard .btn.btn-default:hover {
  box-shadow: 0 8px 25px -8px #82868b;
}
.sideCard li.active span{
  color: #3F8CFF;
}
</style>