<template>
    <section id="dashboard-ecommerce" class="font-14">
        <div class="sec1 d-flex justify-content-between pb-1">
          <div class="d-flex">
            <div @click="handleBack" class="mr-1 d-flex align-items-center mb-25">
              <feather-icon size="24" icon="ArrowLeftIcon" class="text-body" />
            </div>
          <h3 class="text-black">Update Latest Priority</h3>
          </div>
          <div>
          </div>
        </div>
        <div>
          <b-card>
            <button class="btn btn-primary btn-sm m-1 float-right" @click="submitData">Update</button>
      <table class="table">
        <thead>
          <tr>
            <th>Brand ID</th>
            <th>Category</th>
            <th>Cashback Latest Priority</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="3">
              <div class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            </td>
          </tr>
          <tr  v-else-if="data.length == 0">
            <td colspan="3">
            <div class="d-flex justify-content-center align-items-center">
              <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
            </div>
          </td>
          </tr>
          <tr v-else v-for="item in data" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name_en }}</td>
            <td v-if="options[item.id]">
                  <v-select v-model="item.cashback_latest_priority" :options="options[item.id]" :clearable="false"
                    placeholder="Enter Cashback Latest Priority" />
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
        </div>
    </section>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import vSelect from "vue-select";
  export default {
    components: {
      draggable,
      vSelect
    },
    data() {
      return {
        baseURL: process.env.VUE_APP_BASE_URL,
        host:window.location.origin,
        data:[],
        options:{},
        isLoading:true
      }
    }, mounted() {
      this.getData()
    },
    computed:{
      },
    created() {},
    methods: {
      submitData(){
        this.isLoading = true
          this.$http.post(`${this.baseURL}/cashback-brand/update-latest-priority`, {data:this.data},
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }).then((json) => {
              if (json.data.status == 'error') {
                this.isLoading = false
                let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                    ? `${json.data.message}` 
                      : "Error"
                this.$swal({
                  title: "Error!!",
                  text: text,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }else {
              this.isLoading = false
                this.data = []
                json.data.data.map(i=> {
              this.options[i.id] = []
                let data = {
                  cashback_latest_priority:i.cashback_latest_priority,
                  name_en : i.name_en,
                  id: i.id
                }
                let count = json.data.data.length;
                for(let j=1;j<=count;j++){
                  if(i.cashback_latest_priority > count){
                    i.cashback_latest_priority = count
                  }
                  this.options[i.id].push(j)
                  if(i.cashback_latest_priority == j){
                    data.cashback_latest_priority = j
                    }
                }
                this.data.push(data)
              })
                this.$swal({
                  title: "Submitted",
                  text: json.data.message
                    ? `${json.data.message}`
                    : json.data.success
                      ? `${json.data.success}`
                      : "Submitted SuccessFully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
      },
      getData(){
        this.$http.post(`${this.baseURL}/cashback-brand/get-latest-priority`).then((res) => {
              console.log(res.data.data,"res-=-=")
              this.data = []
              res.data.data.map(i=> {
              this.options[i.id] = []
                let data = {
                  cashback_latest_priority:i.cashback_latest_priority,
                  name_en : i.name_en,
                  id: i.id
                }
                let count = res.data.data.length;
                for(let j=1;j<=count;j++){
                  if(i.cashback_latest_priority > count){
                    i.cashback_latest_priority = count
                  }
                  this.options[i.id].push(j)
                  if(i.cashback_latest_priority == j){
                    data.cashback_latest_priority = j
                    }
                }
                this.data.push(data)
              })
              this.isLoading = false
              this.$forceUpdate()
            })
      },
      handleBack(){
        this.$emit("handleClickBack");
      }
    }
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  // .draggable-task-handle {
  //     transform: translateY(-50%);
  //     visibility:visible;
  //     cursor: move;
  
  //     .todo-task-list .todo-item:hover & {
  //       visibility: hidden;
  //     }
  // }
  .danger-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #c90a0a;
    border: 1px solid #c90a0a;
    border-radius: 4px;
  }
  .status-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #0ac947;
    border: 1px solid #0ac947;
    border-radius: 4px;
  }
  .mb-half {
    margin-bottom: 5px;
  }
  .notofication-btn {
    background-color: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    padding: 12px 12px;
  }
  .slide-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    padding: 15px;
    margin: 0;
    width: 200px;
    float: right;
  }
  .see-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    width: 125px;
  }
  .bs-popover-left .popover-body {
    display: flex;
    justify-content: space-between;
    border: none;
  }
  .bs-popover-bottom {
    background-color: #ffffff;
    // height: 190px;
    border: transparent;
    border: none;
    border-radius: 14px;
  }
  .popover-body {
    border: none !important;
  }
  .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffffff;
  }
  .vs-select__options__content {
    background-color: #ffffff !important;
  }
  .pop-btn {
    padding: 0 !important;
  }
  .modal-dialog {
    
  }
  .modal-body {
     padding: 0px 40px 40px 40px !important
  }
  .form-control {
    border-radius: 14px;
  }
  .modal-content {
    border-radius: 24px;
  }
  .modal-header {
    border-radius: 24px;
  }
  textarea.form-control {
    min-height: 150px !important;
  }
  
  .modal-footer {
    display: none;
  }
  .modal-footer1 {
    display: flex;
    float: right;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.4rem;
  }
  .cate-vs-select {
    max-width: none;
  }
  </style>
  