

<style>
.custom-option {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Brands{{ search }}</h1>
      <div>
        
        <button class="btn btn-sm btn-primary" @click="$emit('clickButton','UpdatePopularPriority')">Update Popular Priority</button>
          <button class="btn btn-sm btn-success ml-1"  @click="$emit('clickButton','UpdateLatestPriority')">Update Latest Priority</button>
      </div>
      </div>
        <b-modal ref="modal" id="modal" size="lg" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:400px;padding: 10px 20px;" class="form-row ">
              <div class="form-group col-md-12">
                <h2>Edit Brands</h2>
              </div>
            </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="clickAddBrand">
              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Brand Name (English)">
                    <validation-provider #default="{ errors }" name="Brand Name (English)" rules="required">
                      <b-form-input v-model="name_en" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Brand Name (English)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Cashback Percentage">
                    <validation-provider #default="{ errors }" name="Cashback Percentage" rules="required">
                      <b-form-input v-model="cashback_percentage" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Cashback Percentage" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Status for Cashback Program">
                    <validation-provider #default="{ errors }" name="Status for Cashback Program" rules="required">
                      <v-select 
                      v-model="cashback_status"
                      :options="['Active','Inactive']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Type for Cashback Program">
                    <validation-provider #default="{ errors }" name="Type for Cashback Program" rules="required">
                      <v-select 
                      v-model="cashback_type"
                      :options="['Latest','Popular']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              <div class="col-md-12">
                  <b-form-group class="mb-2" label="Cashback Coupon">
                    <validation-provider #default="{ errors }" name="Cashback Coupon" rules="required">
                      <b-form-input v-model="cashback_coupon" :state="errors.length > 0 ? false : null"
                        placeholder="Cashback Coupon" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Cashback Offer Description (English)">
                    <validation-provider #default="{ errors }" name="Cashback Offer Description (English)" rules="required">
                      <quill-editor v-model="cashback_offer_description" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Cashback Offer Description (Arabic)">
                    <validation-provider #default="{ errors }" name="Cashback Offer Description (Arabic)" rules="required">
                      <quill-editor v-model="cashback_offer_description_ar" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="modal-footer1">
                <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="closeModal()">
                  Cancel
                </button>
                <button type="button" :disabled="invalid" @click="clickAddBrand" class="btn btn-primary"> Update </button>
              </div>
            </b-form>
          </validation-observer>
          <!-- <div>
        <div style="display:flex;">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div> -->
        </b-modal>

        <b-modal id="showmodal" ref="showmodal" size="lg" class="m-2 custom--model">
          <template #modal-header>
            <div >
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.name_en }}
                  </div>
                  <div class="font-weight-bolder text-black">
                        <span v-if="showItem.cashback_status" class="btn btn-outline-success-view">Active</span>
                        <span v-else class="btn btn-outline-danger-view">Inactive</span>
                 </div>
              </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row >
            <b-col md="4" class="d-flex" v-if="showItem.cashback_coupon">
                  <div class="font-weight-bolder text-black">
                    <span class="font-weight-bolder h1" style="color: #3f8cff">{{ showItem.cashback_coupon }}</span>
                  </div>
            </b-col>
            <b-col :md="showItem.cashback_coupon ? 4 : 6">
              <div>
                <div class="mb-half">Cashback Offer Title (English)</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.cashback_offer_title"></div>
              </div>
            </b-col>
           
            <b-col :md="showItem.cashback_coupon ? 4 : 6">
              <div>
                <div class="mb-half">Cashback Offer Title (Arabic)</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.cashback_offer_title_ar"></div>
              </div>
            </b-col>
            <b-col md="12">
              <div>
                <div class="mb-half">Cashback Description Title (English)</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.cashback_offer_description"></div>
              </div>
            </b-col>
            <b-col md="12">
              <div>
                <div class="mb-half">Cashback Description Title (Arabic)</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.cashback_offer_description_ar"></div>
              </div>
            </b-col>
           
          </b-row>
        </b-modal>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="brandData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in brandData"
          @dblclick="handelShow(item)" :key="index">
          <b-row class="p-1">
            <b-col md="3" class="d-flex pl-2">
              <div class="d-flex">
                <div>
                  <div class="mb-half">Name (English)</div>
                  <div class="font-weight-bolder text-black">
                    {{ item.name_en }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                <div class="mb-half">Cashback Coupon</div>
                <div class="font-weight-bolder text-black">
                  <!-- {{item.app_id}} / {{item.web_heading}} -->
                  {{ item.cashback_coupon }}
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                <div class="mb-half">	Offer Title (English)</div>
                <div class="font-weight-bolder text-black" v-html="item.cashback_offer_title"></div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Cashback Status</div>
                <!-- <div class="font-weight-bolder text-black">{{ item.cashback_status }}</div> -->
                <div class="font-weight-bolder">
                  <div class="font-weight-bolder text-black">
                        <span v-if="item.cashback_status" class="btn btn-outline-success btn-sm">Active</span>
                        <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                    </div>
                </div>

              </div>
            </b-col>
            <b-col md="1" class="
                d-flex
                align-items-center
                justify-content-center
                text-black
              ">
              <div :id="'popover-button-variant' + item.id"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
          <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <button type="button" class="btn notofication-btn" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-primary title="View"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%);">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn" @click="handelEdit(item.id)" v-b-modal.modal v-b-tooltip.hover.v-primary title="Edit"
              style="background: rgb(63 140 254 / 13%);">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square"
                viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
          </b-popover>
        </b-card>
      <div class="no-results" :class="{ show: !brandData.length }"></div>
    </div>

    <button v-if="!isLoading && brandData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import json2csv from 'json2csv';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import { codeListGroupAction } from '@/views/components/list-group/code';


// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  data() {
    return {
      selectedValue: [],
      paginationStart: 0,
      paginationEnd: 10,
      brandData: [],
      brandData1: [],
      clientStoreData: [],
      data: {},
      required, integer,
      pageNo: 1,
      name_en: '',
      name_ar: '',
      image_url: '',
      sequence: 0,
      edit: false,
      paginationStart: 0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      checkBoxIds: {},

      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      cashback_status :'',
      cashback_coupon :'',
      cashback_offer_description_ar :'',
      cashback_type:'',
      cashback_percentage:'',
      cashback_offer_description :'',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      popoverShow: false,
      selectAll: false,
      showItem: {},
      value: "1",
    };
  },
  mounted() {
    this.getBrand()
  },
  created() { },
  computed: {
    search() {
      let search = this.$store.state.searchData
      if (this.$store.state.application) {
        this.getSearch(search);
      }
    },
  },
  methods: {

    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },

    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.clientStoreData.map(item => {
        let data = {
          name_en: item.name_en,
          name_ar: item.name_ar,
          category: item.cashback-brand.map((item) => { return item.name }).join(", "),
          description_en: item.description_en,
          description_ar: item.description_ar,
          is_active: item.is_active,
          sequence: item.sequence,
          website: item.website,
          image: item.image,
          search_keyword_en: item.search_keyword_en,
          search_keyword_ar: item.search_keyword_ar,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;

           
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-brand/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.brandData = res.data.data
          this.paginationLength = res.data.totaldata
          this.brandData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    handelEdit(id) {
      this.edit = true
      this.popoverShow = false
      this.editItem = '',
      this.id= '',
      this.name_en= '',
      this.cashback_status= '',
      this.cashback_coupon= '',
      this.cashback_offer_title= '',
      this.cashback_offer_description_ar= '',
      this.cashback_type=''
      this.cashback_percentage='',
      this.cashback_offer_description= '',
      this.$http.post(`${this.baseURL}/cashback-brand/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true 
        }).then((json) => {
          this.editItem = json.data.data
            this.id= this.editItem.id
            this.name_en= this.editItem.name_en
            this.cashback_status= this.editItem.cashback_status ? 'Active' : 'Inactive'
            this.cashback_coupon= this.editItem.cashback_coupon
            this.cashback_offer_description_ar= this.editItem.cashback_offer_description_ar
            this.cashback_type = this.editItem.cashback_type ? 'Latest' : 'Popular'
            this.cashback_percentage= this.editItem.cashback_percentage
            this.cashback_offer_description= this.editItem.cashback_offer_description
        })
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-brand/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.brandData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.brandData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-brand/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.brandData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.brandData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    clickAddBrand(value) {
      let data = {
        id:this.id,
        name_en:this.name_en,
        cashback_status:this.cashback_status == 'Active' ? 1:0,
        cashback_coupon:this.cashback_coupon,
        cashback_offer_description_ar:this.cashback_offer_description_ar,
        cashback_type: this.cashback_type == 'Latest' ? 1 : 0,
        cashback_percentage:this.cashback_percentage,
        cashback_offer_description:this.cashback_offer_description,
      }
      this.$refs.submitPrevent.validate().then((success) => {
        this.$http.post(`${this.baseURL}/cashback-brand/update`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 201) {
              this.getBrand()
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
      })
    },
    async getBrand() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/cashback-brand/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          this.brandData = res.data.data
          this.paginationLength = res.data.totaldata
          this.brandData1 = res.data.data
          this.isLoading = false
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  // z-index: 10000;
  position: absolute;
  right: 6.5cm !important;
  background-color: white;
  border-radius: 26px;
  padding: 5px 15px;
  left: auto;
  top:96px
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 150px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}

.modal-body {
  padding: 0px 40px 40px 40px !important
}

.btn-outline-danger-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: #ea5455;
  // border: 1px solid #ea5455;
  border-radius: 4px;
}

.btn-outline-success-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  // border: 1px solid #0ac947;
  border-radius: 4px;
}
.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}
</style>
