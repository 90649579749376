

<style>
.custom-option {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Data{{ search }}</h1>

      <div v-if="popoverShow" class="popover-manual-1" style="z-index: 10000;">
          <div class="popover-inner">
            <div class="d-flex align-items-center custom-vs-select justify-content-between flex-wrap" style="border: none">
              <button @click="onPopovcerOpen" class="notofication-btn mt-1 mt-md-0 ml-1" style="color: #408dff;" size="sm">
                Cancel
              </button>
            <v-select :searchable="false" v-model="bulk_status" style="width:4cm" shadow :clearable="false" label="name"
              placeholder="Select"  class="mt-1 mt-md-0 ml-1"
              :options="[
                {name:'On Hold',id:0}, 
                {name:'Proccessing',id:1}, 
                {name:'Approved',id:2},
                {name:'Rejected',id:3}, 
                 ]">
            </v-select>
            <b-button @click="handleChangeStatus" class="mt-1 mt-md-0 ml-1" variant="primary" size="sm">
              Bulk Update Status
            </b-button>
          </div>
        </div>
      </div>

      <button id="popover-button-variant" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1" v-b-tooltip.hover.v-primary title="Select All"
        @click="onPopovcerOpen()">
        <div>
          <img id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />
        </div>
      </button>
        <b-modal ref="modal" id="modal" class="m-2 custom--model">
          <template #modal-header>
            <div style="width:400px;padding: 10px 20px;" class="form-row ">
              <div class="form-group col-md-12">
                <h2>Edit Data</h2>
              </div>
            </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="clickAddData">
              <div class="form-row">
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="User Name">
                      <b-form-input v-model="user_name" disabled 
                        placeholder="Enter User Name" />
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Brand Name">
                      <b-form-input v-model="brand_name" disabled 
                        placeholder="Brand Name" />
                  </b-form-group>
                </div>
              <div class="col-md-12">
                  <b-form-group class="mb-2" label="Order ID">
                    <validation-provider #default="{ errors }" name="Order ID" rules="required">
                      <b-form-input v-model="order_id" :state="errors.length > 0 ? false : null"
                        placeholder="Order ID" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Claim ID">
                    <validation-provider #default="{ errors }" name="Claim ID" rules="required">
                      <b-form-input v-model="claim_id" :state="errors.length > 0 ? false : null"
                        placeholder="Claim ID" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Order Date">
                    <validation-provider #default="{ errors }" name="Order Date" rules="required">
                      <flat-pickr
                          v-model="order_date"
                          :config="{
                            dateFormat: 'd/m/Y',
                          }"
                          class="form-control flat-picker bg-transparent shadow-none"
                          placeholder="DD/MM/YYYY"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Order Status">
                    <validation-provider #default="{ errors }" name="Order Status" rules="required">
                      <v-select 
                      v-model="order_status"
                      :options="['On Hold','Processing','Approved','Rejected']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                </div>

              <div class="modal-footer1">
                <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" @click="closeModal()">
                  Cancel
                </button>
                <button type="button" @click="clickAddData" :disabled="invalid" class="btn btn-primary"> Update </button>
              </div>
            </b-form>
          </validation-observer>
          <!-- <div>
        <div style="display:flex;">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div> -->
        </b-modal>

        <b-modal id="showmodal" size="lg" ref="showmodal" class="custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 5px 20px;" class="form-row">
              <div class="form-group col-md-12  ">
                <h2>Data </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row >
            <b-col md="4">
              <div class="d-flex">
                <div>
                  <div class="mb-half">Order ID</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.order_id }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="d-flex">
                <div>
                  <div class="mb-half">Order Date</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.order_date}}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">User ID</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.user_id }}</div>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">User Name</div>
                <!-- <div class="font-weight-bolder h4 text-black" v-html="showItem.user_name"></div> -->
                <div class="font-weight-bolder h4 text-black">{{ showItem.user_name }}</div>

              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Brand Name</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.brand_name"></div>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Coupon Code</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.coupon_code"></div>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Claim ID</div>
                <div class="font-weight-bolder h4 text-black" v-html="showItem.claim_id"></div>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Image</div>
                <b-img v-if="checkURL(showItem.image)" v-bind="mainProps" :src="showItem.image"
                    alt="Circle image" class="d-inline-block roundedImage1" />
                  <b-img v-else v-bind="mainProps"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block roundedImage1" />
              </div>
            </b-col>
           
           
          </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black"
              @click="$refs.showmodal.hide()">
              Close
            </button>
          </div>
        </b-modal>
      </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="brandData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in brandData"
          @dblclick="handelShow(item)" :key="index">
          <b-row class="p-1">
            <b-col md="2">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox
                    v-if="popoverShow"
                    v-model="checkBoxIds[item.id]"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="CheckBoxHandelClick(item.id)"
                  />
                </div>
                <div class="ml-1 mr-2">
                    <b-img v-if="checkURL(item.image)" v-bind="mainProps" :src="item.image"
                      alt="Circle image" class="d-inline-block " />
                    <b-img v-else v-bind="mainProps"
                      :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                      class="d-inline-block " />
                </div>
              </div>
            </b-col>
            <b-col md="10" class="d-flex flex-column">
                <b-row>
                    <b-col md="4" class="d-flex">
                        <div>
                            <div class="mb-half">Order Id</div>
                            <div class="font-weight-bolder text-black">{{item.order_id}}</div>
                        </div>
                    </b-col>
                    <b-col md="4" class="d-flex">
                        <div>
                            <div class="mb-half">Order date</div>
                            <div class="font-weight-bolder text-black">{{item.order_date}}</div>
                        </div>
                    </b-col> 
                    <b-col md="3" class="d-flex">
                        <div>
                            <div class="mb-half">Status</div>
                            <div class="font-weight-bolder text-black">
                              {{
                                item.order_status == 0 ? 'On Hold' : 
                                (item.order_status == 1 ? 'Proccessing' : (item.order_status == 2 ? 'Approved'  : 'Rejected' )  )  
                                }}
                            </div>
                        </div>
                    </b-col>
                    <b-col md="1" class="
                          d-flex
                          align-items-center
                          justify-content-center
                          text-black
                        ">
                        <div :id="'popover-button-variant' + item.id"
                          style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                          <feather-icon icon="MoreVerticalIcon" size="25" />
                        </div>
                      </b-col>
                </b-row>
            </b-col>
          </b-row>
          <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-primary title="View"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>
            <button type="button" class="btn notofication-btn ml-1" @click="handelEdit(item)" v-b-modal.modal v-b-tooltip.hover.v-primary title="Edit"
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square"
                viewBox="0 0 16 16">
                <path
                  d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
              </svg>
            </button>
          </b-popover>
        </b-card>
      <div class="no-results" :class="{ show: !brandData.length }"></div>
    </div>

    <button v-if="!isLoading && brandData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import json2csv from 'json2csv';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from 'vue-flatpickr-component'

import { required, integer } from "@validations";
import { codeListGroupAction } from '@/views/components/list-group/code';


// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'flatpickr/dist/flatpickr.css';

import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
    flatPickr
  },
  data() {
    return {
      selectedValue: [],
      paginationStart: 0,
      paginationEnd: 10,
      brandData: [],
      brandData1: [],
      clientStoreData: [],
      data: {},
      required, integer,
      pageNo: 1,
      name_en: '',
      name_ar: '',
      image_url: '',
      sequence: 0,
      edit: false,
      paginationStart: 0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      checkBoxIds: {},
      user_name:'',
      brand_name:'',
      order_id:'',
      claim_id:'',
      order_date:'',

      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      coupon :'',
      offer_title_en :'',
      offer_description_ar :'',
      offer_description_en :'',
      offer_description_ar :'',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      popoverShow: false,
      order_status: '',
      selectAll: false,
      showItem: {},
      value: "1",
      selectAllLabel: 'Select All',
      bulk_status:''

    };
  },
  mounted() {
    this.getData()
  },
  created() { },
  computed: {
    search() {
      let search = this.$store.state.searchData
      if (this.$store.state.application) {
        this.getSearch(search);
      }
    },
  },
  methods: {
    
    CheckBoxHandelClick(id) {
      this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? true : !this.checkBoxIds[id]
      this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
    },
    handleChangeStatus(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0 && this.bulk_status) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/cashback-data/multi-status-change`, { ids: arr,status:this.bulk_status.id },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getData()
            this.popoverShow= false
            this.selectAllLabel = 'Select All'
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    selectAllClick() {
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.brandData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },

    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.clientStoreData.map(item => {
        let data = {
          user_name:this.user_name,
          brand_name:this.brand_name,
          order_id:this.order_id,
          claim_id:this.claim_id,
          order_date:this.order_date,
          order_status: item.order_status == 0 ? 'On Hold' : 
                                (item.order_status == 1 ? 'Proccessing' : (item.order_status == 2 ? 'Approved'  : 'Rejected' )  )  ,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-data/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.brandData = res.data.data
          this.paginationLength = res.data.totaldata
          this.brandData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    handelEdit(item) {
      this.edit = true
      this.popoverShow = false
      this.editItem = item
      this.user_name = item.user_name
      this.brand_name = item.brand_name
      this.order_id = item.order_id
      this.claim_id = item.claim_id
      this.order_date = item.order_date
      this.order_status = item.order_status == 0 ? 'On Hold' : 
                                (item.order_status == 1 ? 'Proccessing' : (item.order_status == 2 ? 'Approved'  : 'Rejected' )  )  
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-data/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.brandData = res.data.data
        this.selectAllLabel = 'Select All'
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.brandData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-data/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.brandData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.paginationLength = res.data.totaldata
        this.brandData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    clickAddData(value) {
      let brand = []
      this.selectedValue.map(item => {
        brand.push(item.id)
      })
      let data = {
          user_name :this.user_name,
          brand_name :this.brand_name,
          order_id :this.order_id,
          claim_id :this.claim_id,
          order_date :this.order_date,
          order_status : this.order_status == 'On Hold' ? 0 : 
                                (this.order_status == 'Proccessing' ? 1 : (this.order_status == 'Approved'? 2   : 3 )  )  ,
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      this.$refs.submitPrevent.validate().then((success) => {
        this.$http.post(`${this.baseURL}/cashback-data/update/${data.id}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 201) {
              this.getData()
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
      })
    },
    async getData() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/cashback-data/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          this.brandData = res.data.data
          this.paginationLength = res.data.totaldata
          this.brandData1 = res.data.data
          this.isLoading = false
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual-1{
  // z-index: 10000;
  position: absolute;
  right: 2.7cm !important;
  background-color: white;
  border-radius: 26px;
  padding: 5px 15px;
  left: auto;
  top:0px
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 150px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}

.modal-body {
  padding: 0px  40px!important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}
</style>
