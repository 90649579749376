

<style>
.custom-option {
  display: flex;
  align-items: center;
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Users{{ search }}</h1>
      </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="userData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
     
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in userData" :key="index" @dblclick="handelShow(item)">
          <b-row class="p-1">
            <b-col md="3" class="d-flex pl-2">
              <div class="d-flex">
                <div>
                  <div class="mb-half">User Name</div>
                  <div class="font-weight-bolder text-black">
                    {{ item.user_name }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                <div class="mb-half">Email</div>
                <div class="font-weight-bolder text-black email_font  text-truncate">
                 {{ item.email }}
                  <!-- {{item.app_id}} / {{item.web_heading}} -->
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                <div class="mb-half">Wallet</div>
                <div class="font-weight-bolder text-black">{{ item.wallet }}</div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex">
              <div>
                <div class="mb-half">	Withdrawal Wallet</div>
                <div class="font-weight-bolder text-black">{{ item.	withdrawal_amount }}</div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      <div class="no-results" :class="{ show: !userData.length }"></div>
    </div>

    <b-modal id="showmodal" size="lg" ref="showmodal" class="custom--model">
          <template #modal-header>
            <div style="width:80%;padding: 5px 20px;" class="form-row">
              <div class="form-group col-md-12  ">
                <h2>User </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row >
            <b-col md="4">
              <div class="d-flex">
                <div>
                  <div class="mb-half">User Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.user_name }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4">
              <div class="d-flex">
                <div>
                  <div class="mb-half">Email</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.email}}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Wallet</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.wallet }}</div>
              </div>
            </b-col>
            <b-col md="4">
              <div>
                <div class="mb-half">Withdrawal Wallet</div>
                <!-- <div class="font-weight-bolder h4 text-black" v-html="showItem.user_name"></div> -->
                <div class="font-weight-bolder h4 text-black">{{ showItem.withdrawal_amount }}</div>

              </div>
            </b-col>
          </b-row>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black"
              @click="$refs.showmodal.hide()">
              Close
            </button>
          </div>
        </b-modal>

    <button v-if="!isLoading && userData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import json2csv from 'json2csv';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import { codeListGroupAction } from '@/views/components/list-group/code';
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  data() {
    return {
      selectedValue: [],
      paginationStart: 0,
      paginationEnd: 10,
      userData: [],
      userData1: [],
      clientStoreData: [],
      data: {},
      required, integer,
      pageNo: 1,
      name_en: '',
      name_ar: '',
      image_url: '',
      sequence: 0,
      edit: false,
      paginationStart: 0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      checkBoxIds: {},

      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      popoverShow: false,
      status: '',
      selectAll: false,
      showItem: {},
      value: "1",
    };
  },
  mounted() {
    this.getUser()
  },
  created() { },
  computed: {
    search() {
      let search = this.$store.state.searchData
      if (this.$store.state.application) {
        this.getSearch(search);
      }
    },
  },
  methods: {
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.clientStoreData.map(item => {
        let data = {
          name_en: item.name_en,
          name_ar: item.name_ar,
          category: item.categories.map((item) => { return item.name }).join(", "),
          description_en: item.description_en,
          description_ar: item.description_ar,
          is_active: item.is_active,
          sequence: item.sequence,
          website: item.website,
          image: item.image,
          search_keyword_en: item.search_keyword_en,
          search_keyword_ar: item.search_keyword_ar,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-user/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.userData = res.data.data
          this.paginationLength = res.data.totaldata
          this.userData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),

    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-user/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.userData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.userData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/cashback-user/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.userData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.userData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    async getUser() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/cashback-user/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.userData = res.data.data
          this.paginationLength = res.data.totaldata
          this.userData1 = res.data.data
          this.isLoading = false
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  // z-index: 10000;
  position: absolute;
  right: 6.5cm !important;
  background-color: white;
  border-radius: 26px;
  padding: 5px 15px;
  left: auto;
  top:96px
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 150px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  
}
.email_font{
    font-size: 13px;
    width: 200px;
}

// .modal-body {
//    padding: 0px 40px 40px 40px !important
// }

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}
</style>
